#fps_indicator {
  position: absolute;
  text-align: center;
  font-size: 16px;
  top: 15px;
  left: 0;
  width: 60px;
  height: 20px;
  display: none;
}

#inspector-host input,
#inspector-host select {
  color: #000;
}

.actionTools {
  position: absolute !important;
  bottom: 0;
  right: 0;
  margin: 10px;
  z-index: 10000;
}

#babylonjsLoadingDiv {

  z-index: 1500;
}